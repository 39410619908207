<template>
    <div>
        <CRow>
            <CCol col="12">
                <CCard>
                    <CCardHeader>E-Posta Şeması Oluştur</CCardHeader>
                    <CCardBody>
                        <CTabs>
                            <CTab title="Genel Bilgiler" active>
                                <CForm>
                                    <CInput
                                        label="Ürün ismi"
                                        horizontal
                                        autocomplete="productName"
                                        :value.sync="product.name"
                                    />
                                    <CInput
                                        label="Alt başlık"
                                        description="Ürün isminin yanındaki yazı. UNISEX gibi"
                                        horizontal
                                        :value.sync="product.subTitle"
                                    />
                                    <label>Ürün Açıklaması</label>
                                    <VueEditor v-model="product.description" />
                                    <CRow class="form-group" form>
                                        <CCol col="6">
                                            <CInput
                                                label="Yurtiçi fiyatı"
                                                horizontal
                                                :value.sync="product.prices.domestic"
                                            />
                                            <CInput
                                                label="Sepette indirim"
                                                horizontal
                                                :value.sync="product.discount"
                                                description="Yüzde olarak girilmelidir"
                                            />
                                        </CCol>
                                        <CCol col="6">
                                            <CInput
                                                label="Yurtdışı fiyatı"
                                                horizontal
                                                :value.sync="product.prices.abroad"
                                            />
                                            <CInput
                                                label="Sıra"
                                                horizontal
                                                :value.sync="product.order"
                                            />
                                        </CCol>
                                    </CRow>

                                    <CInput
                                        label="Kampanya Metni"
                                        horizontal
                                        :value.sync="product.campaign"
                                    />
                                    <CSelect
                                        label="Kategori"
                                        horizontal
                                        :value.sync="product.category"
                                        :options="productCategories"
                                        placeholder="Kategori seçiniz"
                                    />

                                    <CRow form class="form-group">
                                        <CCol tag="label" sm="3" class="col-form-label">
                                            Ücretsiz Kargo
                                        </CCol>
                                        <CCol sm="9">
                                            <CSwitch
                                                class="mr-1"
                                                color="primary"
                                                :checked="product.freeShipment"
                                            />
                                        </CCol>
                                    </CRow>
                                </CForm>
                            </CTab>
                            <CTab title="Bedenler">
                                <CForm>
                                    <CRow>
                                        <CCol col="6">
                                            <CInput
                                                label="Beden ismi"
                                                :value.sync="newSize.name"
                                            />
                                        </CCol>
                                        <CCol col="4">
                                            <CInput
                                                label="Stok sayısı"
                                                :value.sync="newSize.count"
                                            />
                                        </CCol>
                                        <CCol col="2">
                                            <CButton
                                                style="float: right; margin-top: 30px"
                                                type="button"
                                                size="sm"
                                                color="primary"
                                                v-on:click="addSize()"
                                            >
                                                <CIcon name="cil-check-circle"/> Ekle
                                            </CButton>
                                        </CCol>
                                    </CRow>
                                    <CRow v-for="size in product.sizes" :key="size.id">
                                        <CCol col="6">
                                            <CInput
                                                label="Beden ismi"
                                                :value.sync="size.name"
                                            />
                                        </CCol>
                                        <CCol col="6">
                                            <CInput
                                                label="Stok sayısı"
                                                :value.sync="size.count"
                                            />
                                        </CCol>
                                    </CRow>
                                </CForm>
                            </CTab>
                            <CTab title="Resimler">
                                <h1>Ürün resimleri ürün kaydedildikten sonra oluşturulabilir.</h1>
                            </CTab>
                        </CTabs>
                    </CCardBody>
                    <CCardFooter>
                        <CButton
                            type="button"
                            size="sm"
                            color="primary"
                            v-on:click="save()"
                        >
                            <CIcon name="cil-check-circle"/> Kaydet
                        </CButton>
                    </CCardFooter>
                </CCard>
            </CCol>
        </CRow>
    </div>
</template>

<script>
import axios from 'axios';
import { VueEditor } from 'vue2-editor';
import { getProductCategories } from '../../utils';


export default {
    name: 'MailTemplateCreate',

    components: {
        VueEditor
    },

    data () {
        return {
            item: {
                name: '',
                description: '',
                subject: '',
                content: '' 
            },
            alert: {
                isOpen: false,
                color: '',
                message: ''
            },
        }
    },

    methods: {
        closeAlert() { this.alert.isOpen = false; },

        addSize() {
            this.product.sizes.push(this.newSize);
            this.newSize = {
                name: '',
                count: 0
            };
        },

        async save() {
            try {
                const response = await axios({
                    method: 'post',
                    url: '/mail-templates',
                    data: {
                        ...this.product,
                        prices: {
                            domestic: Number(this.product.prices.domestic),
                            abroad: Number(this.product.prices.abroad)
                        },
                        sizes: this.product.sizes.map(x => ({
                            name: x.name,
                            count: Number(x.count)
                        }))
                    }
                });

                if (response.status === 201) {
                    this.$router.push({ path: `${response.data.id}` });
                }
            } catch (error) {
                console.log(error)
                this.alert = {
                    isOpen: true,
                    color: 'danger',
                    message: 'İşlem gerçekleştirilirken bir hata oluştu'
                };
            }
        },
    },
}
</script>

<style lang="scss" scoped>
    label {
        margin-bottom: 15px;
    }
</style>
